import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import * as signalR from '@microsoft/signalr';
import { navigationRef } from '../services/utils';
import { User } from '../types';
import { updateDeviceToken, deleteDeviceToken as deleteDeviceTokenApiCall } from '../services/api/notification.api';
import { urlBase, vapidKey } from '../../configuration';
import useLoggedUser from './useLoggedUser';
import { notificationHandler } from './usePushNotificationCommon';

initializeApp({
  apiKey: 'AIzaSyBtoAc81s3IjnuGXcvqXLGV9Xrqn0UWbVM',
  authDomain: 'konecteam-notification.firebaseapp.com',
  projectId: 'konecteam-notification',
  storageBucket: 'konecteam-notification.appspot.com',
  messagingSenderId: '78519534298',
  appId: '1:78519534298:web:711b972ae71a347652dbe3',
  measurementId: 'G-T2JEXKPF12',
});

export function registerDeviceToken(user: User) {
  requestPermission(async () => {
    try {
      const token = await getToken(getMessaging(), { vapidKey });
      await updateDeviceToken(user, token);
    } catch (error) {
      console.error(error);
    }
  });
}

export async function deleteDeviceToken(user: User) {
  requestPermission(async () => {
    try {
      const token = await getToken(getMessaging(), { vapidKey });
      await deleteDeviceTokenApiCall(user, token);
    } catch (error) {
      console.error(error);
    }
  });
}

export function usePushNotifications(activated: boolean) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const accessToken = user?.token;

  useEffect(() => {
    if (navigationRef.current && activated) {
      // Listen to signalR
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${urlBase}notification?access_token=${accessToken}`)
        .withAutomaticReconnect()
        .build();

      connection.on('message', (message) => {
        console.log(message);
        return notificationHandler(message as any, dispatch);
      });

      connection.start().catch((err) => console.error(err));
    }
  }, [navigationRef.current, accessToken, activated]);
}

function requestPermission(callback: () => void): void {
  if (typeof Notification === 'undefined') {
    return;
  }

  Notification.requestPermission().then(() => callback());
}
